<template>
  <v-menu
    ref="menu"
    :close-on-content-click="false"
    offset-y
    :nudge-bottom="4"
    max-width="290px"
    min-width="290px"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="btn-activator" dark rounded color="#465286" v-on="on">
        <div class="d-flex align-center w-100">
          <v-icon class="mr-2">mdi-calendar</v-icon>
          <div>{{ formattedDate }}</div>
          <v-spacer />
          <v-icon>mdi-chevron-down</v-icon>
        </div>
      </v-btn>
    </template>
    <v-date-picker
      range
      scrollable
      header-color="#465286"
      type="month"
      :value="value"
      @input="onChangeDate"
    />
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'VDateRangePicker',
  props: ['value', 'format'],
  computed: {
    formattedDate() {
      const { value } = this;
      let { format } = this;
      if (!value) {
        return '';
      }
      if (!format) {
        format = 'MMM YYYY';
      }
      return `${moment(this.value[0]).format(format)} - ${moment(this.value[1]).format(format)}`;
    },
  },
  methods: {
    onChangeDate(date) {
      this.$emit('input', date);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-activator {
  width: 320px;
}
</style>
