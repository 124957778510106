<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="fs-16 justify-end" :color="color" v-bind="attrs" v-on="on">
        mdi-information-outline
      </v-icon>
    </template>
    <span class="white--text">
      <slot />
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'HelpTooltip',
  props: {
    color: {
      type: String,
      default: '#ADB7C1',
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
