<template>
  <div>
    <div class="page-title">{{ title }}</div>
    <div class="d-flex my-6">
      <v-date-range-picker v-model="dateRange" />
      <v-spacer />
      <v-btn dark rounded color="#465286" v-if="false">
        Report
        <v-icon class="px-3" size="16">mdi-share-variant-outline</v-icon>
        <v-divider vertical />
        <v-icon class="pl-3" size="16">mdi-download</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import VDateRangePicker from '@/components/App/VDateRangePicker.vue';

export default {
  name: 'PageHeader',
  components: {
    VDateRangePicker,
  },
  props: ['title'],
};
</script>

<style lang="scss" scoped>
.page-title {
  text-align: center;
  font-size: 36px;
  color: #2A346F;
}
</style>
